<template>
  <div id="projects">
    <md-table
      v-if="view == 'table'"
      class="table-altice"
      v-model="searched"
      @md-selected="selected = event"
      :md-sort.sync="sortColumn"
      :md-sort-order.sync="sortOrder"
      md-card>
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title" v-if="!multileCustomer"><span v-if="projects[0]">{{ projects[0].customerName }}</span></h1>
        </div>

        <md-field md-clearable class="md-toolbar-section-start">
          <md-input placeholder="Pesquisar projeto..." v-model="search" @input="searchOnTable" />
        </md-field>
        <md-button class="md-icon-button" @click="download()">
          <md-icon>download</md-icon>
        </md-button>
        <!-- <md-button class="md-icon-button md-dense md-raised md-primary" @click="view = 'gantt'">
          <md-icon>short_text</md-icon>
        </md-button> -->
      </md-table-toolbar>

      <md-table-empty-state
        md-label=""
        :md-description="`Nenhum projeto encontrado.`">
      </md-table-empty-state>

      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="{ 'md-row-background': item.selected }" @mouseover="onSelectTable(item)" @mouseleave="offSelectTable(item)">
        <md-table-cell md-label="PROJETO" md-sort-by="lt"><b>{{ item.lt }}</b></md-table-cell>
        <md-table-cell v-if="multileCustomer" md-label="Empresa" md-sort-by="customerName">{{ item.customerName }}</md-table-cell>
        <md-table-cell style="max-width: 250px;" md-label="DESCRIÇÃO" md-sort-by="description">{{ item.description }}</md-table-cell>
        <md-table-cell md-label="DATA INÍCIO" md-sort-by="start">{{ item.start | dateFormat('DD/MM/YYYY') }}</md-table-cell>
        <md-table-cell md-label="DATA OBJETIVO" md-sort-by="end">{{ item.end | dateFormat('DD/MM/YYYY') }}</md-table-cell>
        <md-table-cell md-label="ÚLTIMA ATUALIZAÇÃO" md-sort-by="last">{{ item.last | dateFormat('DD/MM/YYYY') }}</md-table-cell>
        <md-table-cell md-label="GESTOR PROJETO" md-sort-by="manager">{{ item.manager }}</md-table-cell>
        <md-table-cell md-label="" md-sort-by="link">
          <md-button class="md-raised md-secondary" @click="detail(item)">Detalhe <md-icon class="icon-menu">keyboard_arrow_right</md-icon></md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-content  v-if="view == 'table'" id="projects" class="md-elevation-4 md-alignment-center-center table-altice">
      <gantt v-if="projects.length" style="padding-left: 10px;" :data="searched" :changeData="changeData"></gantt>
    </md-content>
  </div>
</template>

<script>
import Gantt from '@/components/Gantt.vue'
import Projects from '@/api/services/Projects.js'
import Auth from '@/api/services/Auth.js'
import { sortBy, orderBy } from 'lodash-es'
import * as XLSX from 'xlsx/xlsx'

// Table Material
const toLower = text => {
  return text.toString().toLowerCase()
}

// const searchByDescription = (items, term) => {
//   if (term) {
//     return items.filter(item => toLower(item.description).includes(toLower(term)))
//   }

//   return items
// }
export default {
  name: 'Projects',
  computed: {
    filteredItems () {
      return this.projects
    },
    searched: {
      get: function () {
        // i just used lodash; lazy, i know...
        if (this.sortColumn === 'start') {
          return orderBy(this.projects, (a) => new Date(a.start), [this.sortOrder])
        } else if (this.sortColumn === 'end') {
          return orderBy(this.projects, (a) => new Date(a.end), [this.sortOrder])
        } else if (this.sortColumn === 'last') {
          return orderBy(this.projects, (a) => new Date(a.last), [this.sortOrder])
        }
        return sortBy(this.projects, this.sortColumn)
      },
      set: (items) => {
        return items
      }
    }
  },
  components: {
    Gantt
  },
  watch: {
    sortColumn () {
      this.$nextTick(() => (this.sortOrder = 'desc'))
    }
  },
  data () {
    return {
      view: 'table',
      search: '',
      allProjects: [],
      selected: {},
      selectedGantt: '',
      projects: [],
      history: '',
      changeData: {},
      multileCustomer: false,
      sortColumn: 'lt',
      sortOrder: 'asc',
      columns: [
        {
          label: 'Projetos',
          field: 'lt'
        },
        {
          label: 'Empresa',
          field: 'customerName'
        },
        {
          label: 'DESCRIÇÃO',
          field: 'description'
        },
        {
          label: 'DATA INÍCIO',
          field: 'start'
        },
        {
          label: 'DATA OBJETIVO',
          field: 'end'
        },
        {
          label: 'ÚLTIMA ATUALIZAÇÃO',
          field: 'last'
        },
        {
          label: 'GESTOR PROJETO',
          field: 'manager'
        },
        {
          label: 'Data do PDS',
          field: 'date_status_report'
        },
        {
          label: 'PDS',
          field: 'last_pds_description'
        }
      ]
    }
  },
  methods: {
    searchByDescriptionAndLT (items, term) {
      if (term) {
        return items.filter(item => {
          return toLower(item.description).includes(toLower(term)) || toLower(item.lt).includes(toLower(term))
        })
      } else {
        return this.allProjects
      }
    },
    download () {
      this.$loading(true)
      let createXLSLFormatObj = []
      let newXlsHeader = []
      let vm = this
      var projects = []
      vm.projects.forEach(item => {
        var element = {}
        element = item
        Projects.getLtsHistory(item.lt).then(response => {
          this.$loading(true)
          element.last_pds_description = response.data.results.length > 0 ? response.data.results[0].status_md.replace(/(<([^>]+)>)/gi, '').replace(/(&nbsp;)+/g, ' ') : ''
          element.date_status_report = response.data.results.length > 0 ? response.data.results[0].date_status_report : ''
        })
        this.$loading(true)
        projects.push(element)
      })
      this.$loading(true)
      setTimeout(() => {
        this.$loading(true)
      }, 1000)
      setTimeout(() => {
        this.$loading(true)
        if (vm.columns.length === 0) {
          return
        }
        if (projects.length === 0) {
          return
        }
        vm.columns.map(column => {
          newXlsHeader.push(column.label)
        })
        createXLSLFormatObj.push(newXlsHeader)
        projects.map(value => {
          let innerRowData = []
          vm.columns.map(val => {
            let fieldValue = value[val.field]
            if (val.field.split('.').length > 1) {
              fieldValue = this.getNestedValue(value, val.field)
            }
            if (val.dataFormat && typeof val.dataFormat === 'function') {
              innerRowData.push(val.dataFormat(fieldValue))
            } else {
              innerRowData.push(fieldValue)
            }
          })
          createXLSLFormatObj.push(innerRowData)
        })
        let fileName = 'projects.xlsx'
        let wsName = 'projects'
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
        XLSX.utils.book_append_sheet(wb, ws, wsName)
        XLSX.writeFile(wb, fileName)
        this.$loading(false)
      }, 4000)
    },
    isMultiple () {
      var temp = ''
      var result = this.projects.filter((value) => {
        if (temp === '') {
          temp = value.customerName
        }
        return value.customerName === temp
      }).length
      this.multileCustomer = result !== this.projects.length
    },
    getPercent (start, end) {
      var t = ((new Date().getTime() - new Date(start).getTime()) / (1000 * 3600 * 24)) * 100
      var day = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 3600 * 24)
      var simple = t / day + (Math.random() * (20 - 5) + 10)
      var result = simple >= 100 ? 90 : simple < 0 ? 0 : simple
      return Math.floor(result)
    },
    getLts (lts) {
      // Projects.getLts(lts).then((response) => {
      //   return response.data.results
      // }).catch(() => {
      //   console.log('history error')
      //   return ''
      // })
      return new Promise((resolve, reject) => {
        Projects.getLts(lts)
          .then(response => resolve(response))
          .catch(() => reject)
      })
    },
    getProjects () {
      return new Promise((resolve, reject) => {
        Projects.getProjects()
          .then(response => resolve(response))
          .catch(() => reject)
      })
    },
    ganttSelected (item) {
      this.onSelectTable(this.projects[item])
    },
    onSelectTable (item) {
      // console.log(this)
      this.projects.forEach(item => {
        item.selected = false
      })
      item.selected = true
      this.changeData = item
    },
    offSelectTable (item) {
      item.selected = false
      this.changeData = {}
    },
    searchOnTable () {
      this.projects = this.searchByDescriptionAndLT(this.allProjects, this.search)
    },
    detail (item) {
      this.$store.commit('setProject', item)
      this.$router.push({ path: `/detail/${item.lt}` })
    }
  },
  created () {
    this.$loading(true)
    if (this.$store.state.internal) {
      this.$loading(false)
      Auth.logout(true)
    }
    // console.log(Projects)
    Projects.getProjects().then((response) => {
      response.data.results.forEach(object => {
        if (new Date(object.date_award) > new Date(object.planned_date)) {
        } else {
          Projects.getLts(object.lt).then((responseInfo) => {
            this.projects.push({
              'lt': object.lt,
              'customerName': object.customer_name,
              'description': object.opportunity_description,
              'projectInfo': responseInfo.data.results[0],
              'start': new Date(object.date_award),
              'end': new Date(object.planned_date),
              'last': new Date(object.date_last_update),
              'manager': object.project_manager_name,
              'percent': object.execution !== null ? object.execution : 0,
              // 'percent': this.getPercent(new Date(object.date_award), new Date(object.planned_date)),
              'selected': false
            })
          }).catch(() => {
          })
        }
      })
      this.allProjects = this.projects
      this.isMultiple()
      this.$loading(false)
    }).catch(() => {
      this.allProjects = this.projects
      this.isMultiple()
      this.$loading(false)
    })
  }
}
</script>

<style lang="scss">
#projects{
  text-align: left;
  margin-bottom: 50px;
}
#chart_div{
  padding: 15px;
}
.card-altice {
  margin: auto auto;
  max-width: 1000px;
  // min-height: 45vh;
}
.table-altice {
  margin: auto auto;
  max-width: 95vw;
}
.middle-card {
  padding-top: 10vh;
  padding-left: 20px;
  padding-right: 20px;
}

.md-title{
  font-weight:700!important;
}

.md-raised{
  border-radius:5px;
  box-shadow: none!important;
  border: 2px solid black;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:40px;
  background-color: transparent !important;
  .icon-menu{
    font-size:10px!important;
    color:black!important;
    width:auto!important;
    min-width:auto!important;
  }
}

.md-raised:hover{
  border-radius:5px;
  box-shadow: none!important;
  border-color: #0084D6!important;
  padding: 10px 15px;
  font-size:10px;
  letter-spacing: 0.12em;
  font-weight:700;
  height:40px;
  background-color:#0084D6!important;

  .md-button-content{
    color:white!important;
    .icon-menu{
      color:white!important;
    }
  }
}

.md-toolbar{
  padding-top:5px;
}

.toolbar-gantt {
  background-color: #ffffff !important;
}

.md-row-background{
  color: rgba(0, 0, 0, 1) !important;
}

.md-row-background:hover{
  font-weight: 500;
  transition: none;
}

.md-ripple{
  color: black !important;
}

.md-field:before{
  background-color:#0084D6!important;
  height:1px!important;
}

.md-field:after{
  background-color:#0084D6!important;
}

.md-table-empty-state{
  background-color:#f6f6f6!important;
  max-width:100%!important;
}

.md-empty-state{
  margin:0!important;
}

.md-empty-state-container[data-v-6cfeddb4]{
  padding:20px!important;
}

.md-empty-state-description{
  font-size:14px!important;
}

.md-table-head-label{
  color:black!important;
  font-weight:400!important;
}

.md-table-head-container:hover{
  .md-table-head-label{
    color:#0084D6!important;
    font-weight:700!important;
  }
}

.md-table-sortable-icon{
  right:8px;
  left:auto;
  svg{
    fill:#0084D6!important;
  }
}
</style>
